<template>
  <article v-if="newDesign" class="badge-wrapper" @click="$router.push(path)">
    <div class="badge__content">
      <component :is="`h${headingLevel}`" class="title"
        >Die {{ $brand.name }} App für Ihr Smartphone</component
      >
      <Checklist class="badge--content-features">
        <li>Einfache Kontrolle von Guthaben & Freieinheiten</li>
        <li>Flexible Auflademöglichkeiten</li>
        <li>Schneller und bequemer Tarifwechsel</li>
        <li>Direkter Kontakt zum {{ $brand.name }} Support</li>
      </Checklist>
      <img
        class="badge__content-image"
        :src="AppTeaser"
        alt="Screenshot der Startseite der App"
      />
      <ButtonComponent
        disable-full-width
        :clickhandler="() => $router.push(path)"
        :href="$router.resolve(path).href"
        class="badge__content-button"
        @click.stop
        >Über die {{ $brand.name }} App</ButtonComponent
      >
    </div>
  </article>
  <article v-else class="badge" @click="$router.push(path)">
    <div class="badge--content">
      <component :is="`h${headingLevel}`" class="title"
        >Unsere Top App</component
      >

      <p>
        Mit der {{ $brand.name }}-App haben Sie Ihren Tarif auch unterwegs voll
        im Griff.
      </p>
    </div>

    <div class="badge--images">
      <ImageComponent :src="AppImage" height="220" width="147" />
      <ImageComponent
        height="156"
        width="160"
        :src="FreeAppImage"
        alt="Kostenlos für iOS und Android"
      />
    </div>

    <ButtonComponent
      :clickhandler="() => $router.push(path)"
      :href="$router.resolve(path).href"
      class="badge--button"
      @click.stop
      >Über die App</ButtonComponent
    >
  </article>
</template>

<script>
import AppImage from './resources/app-${BRAND_CODE}.webp'
import AppTeaser from './resources/app-teaser-${BRAND_CODE}.webp'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Checklist from 'src/components/common/checklist/checklist.vue'
import FreeAppImage from './resources/free-app-${BRAND_CODE}.webp'
import ImageComponent from 'src/components/common/image/image.vue'

export default {
  name: 'TopApp',
  components: { Checklist, ButtonComponent, ImageComponent },
  props: {
    headingLevel: {
      type: Number,
      default: 3
    },
    newDesign: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      path: { name: 'PortalServiceApp' },
      AppImage,
      FreeAppImage,
      AppTeaser
    }
  },
  methods: {
    // remove after fonic app is launched
    isFonicMobile() {
      return this.$brand.code === 'fonic-mobile'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../badges';

.badge__content {
  display: grid;
  grid-template-columns: 1fr;

  @include breakpoint($from-desktop) {
    grid-template-columns: 1fr 1fr;
  }
}

.badge--images {
  display: flex;
  align-items: center;
  justify-content: center;

  > picture {
    max-width: 45%;
  }
}

.badge__content-image {
  padding: 20px 30px 0;
  max-width: 90%;
  object-fit: contain;
  justify-self: center;

  @include breakpoint($from-desktop) {
    max-width: initial;
    justify-self: start;
    order: -1;
    grid-row: span 3;
    grid-column: 1;
  }
}

.badge__content-button {
  margin-top: 0;

  @include breakpoint($from-desktop) {
    width: fit-content;
    margin-top: 25px;
    margin-bottom: 70px;
    max-height: 44px;
  }
}
</style>
